import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).use(initReactI18next)

  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      EN: {
        translation: {
          NavBar: {
            BecomeAPartner: "Become a partner",
            News: "News",
            Advantages: "Advantages",
            ContactUs: "Contact us",
            LogIn: "Log in",
            SignUp: "Sign up",
          },
          Flag: {
            iconClassName: "flag-icon flag-icon-us me-1",
          },
          StartTheRace: {
            EarnMoreWithOur: "Earn more with our",
            JoinOurGlobal: "Join our global",
            AffiliateProgram: " affiliate program",
            BecomeAPartner: " Become a partner",
            ReferOthersTo: "Refer others to ",
            nabcasino: "nabcasino.com ",
            throughYourLinks:
              " through your links, earn commissions on their registrations and play.",
          },
          BecomeAPartner: {
            titleGray: "Become",
            titleWhite: " a partner",
            TexteWhite: "Nabcasino.com  ",
            TexteGray:
              "is where you can enjoy competitive odds  and the best Bitcoin betting experience. Our team strives for a fulfilling, fun,  and fair betting journey, offering you a top-notch interactive platform.",
            EarnRevenuetitle: "Earn Revenue",
            EarnRevenuetext:
              "Earn up to 50% revenue share with no quotas as an affiliate!",
            GameOnTitle: "Game On",
            GameOntext:
              "Explore endless excitement with 5000+ games at our casino. From classics to new releases, we've got your gaming adventure covered!",
            GlobalReachTitle: "Global Reach",
            GlobalReachtext: ` Experience our platform's global accessibility, reaching users around the world with support for 16 languages. `,
          },
          Casino: {
            Title: "Casino royale",
            smallText:
              'Step into the world of glamour and chance in our premier <span class="SportsToPlaysmallTextwhite">Casino section!</span>',
            LiveCasinoTitle: "Live Casino",
            LiveCasinoText:
              "Engage with real dealers in our Live Casino. Real-time action for those who crave the thrill of the game floor.",
            CalssicslotsTitle: " Classic Slots",
            CalssicslotsText:
              "Spin the reels and feel the rush with classic slots. Timeless fun with a sprinkle of luck and nostalgia.",
            TableGamesTitle: "Table Games",
            TableGamesText:
              "Challenge the odds with a variety of table games. From blackjack to roulette, it’s your strategy against the house.",
          },

          SportsPlay: {
            Title: "Sports play",
            smallText:
              'Dive into the heart of the action with our <span class="SportsToPlaysmallTextwhite">Sports section</span> at your fingertips!',
            HorseRacingTitle: "Horse Racing",
            HorseRacingText:
              "Feel the excitement of horse racing—fast-paced action and thrilling moments await!",
            AussieRulesTitle: "Aussie Rules",
            AussieRulesText:
              "Enjoy Aussie Rules—high-flying plays and intense competition for non-stop fun!",
            FootballTitle: "Football",
            FootballText:
              "Kick into football action—goals, strategies, and excitement for all fans!",
          },

          Advantages: {
            Title: "Advantages",
            smallText:
              'Team up with us and get up to <span class="SportsToPlaysmallTextwhite">50% in Revenue share</span> -its that simple',
            DetailedStatsTitle: "Detailed Stats",
            DetailedStatsText:
              "Track campaigns, customers, and traffic in real-time with our efficient affiliate system.",
            LifetimeRevenueTitle: "Lifetime Revenue",
            LifetimeRevenueText:
              "Unlock limitless earnings with increased traffic. Our generous payout ensures you earn from every player you attract.",
            FastAffiliatePaymentsTitle: "Fast Affiliate Payments",
            FastAffiliatePaymentsText:
              "Count on prompt payments—commissions processed and paid within the first five business days every month for our affiliates.",
          },

          ContactUs: {
            Title:
              'Contact <span class="SportsToPlaysmallTextwhite popins-bold">our affiliate team</span> ',
            smallText:
              'Feel free to <span class="ContactUsFeelFreewhite popins-bold">  contact us </span>  any time. We will get back to you as soon as we can.',
            SendUsMessage: "Send us message",
            iHaveReadAndAgreed:
              ' I have read and agree to the <span class="ContactUsiHaveReadAndAgreedUnderline ">  terms of service  </span> and  <span class="ContactUsiHaveReadAndAgreedUnderline ">privacy</span>',
            SendRequest: "Send request",
            YourEmail: "YOUR E-MAIL*",
            MessageText: "MESSAGE TEXT*",
          },

          Footer: {
            Textup: "Become a partner",
            TextMidle: "Contact us",
            TextDownTerms: "Terms of Conditions",
            TextDownHome: "Home",
            AllRights: "All Rights Reserved, New Age N.V",
          },

          Events: {
            Title: "Events",
            SubtitleWhite: "Join us",
            Subtitle: "for upcoming events and stay connected!",
          },

          Testimonials: {
            Title: "Testimonials",
            Subtitle1: "See how our affiliates are",
            SubtitleWhite: " succeeding ",
            Subtitle2: "with us!",

   
            description1:"Partnering with NAB Affiliates has been excellent. Their crypto offerings enhance our site, and the team is always helpful. Adding nabcasino.com was easy due to their innovation and strong leadership. We look forward to continued success with such a reliable affiliate program.",
      
            description2:"From the beginning, working with Nab Affiliates has been a pleasure. Our account manager has established a great rapport with us and has been very professional and responsive to our needs. We wholeheartedly advise working with  Nab Affiliates!",  
           
            description3:"For The Gambler Bay, partnering with NAB AFFILIATES is a very important step. We have long been striving for this and believe that this is a mutually beneficial investment of forces. NAB AFFILIATES is the embodiment of functions that are absolutely necessary for modern affiliate programs. Playing for real money in online casinos is an important step, and you fully support the customers of your casinos!",
      
            description4:"Nab Affiliates continues to set the standard in their industry. Their dedication to achieving top-tier results and their proactive approach make them an irreplaceable asset. We wholeheartedly endorse their services.",
        
            description5:"Affiliate marketing has evolved into a powerful communication strategy for our organization, creating new opportunities for growth and boosting profits. By partnering with a dependable affiliate, NABCASINO, we have been able to broaden our reach substantially and strengthen customer loyalty by endorsing services that genuinely address their requirements. ",
   
            description6:"Working with professionals is fun. They have a reputable casino with reasonable affiliate partnerships in numerous nations. Strongly advise Nab Affiliates.",
    
            description7:"Working with NAB AFFILIATES has been a fantastic experience. Their affiliate program offers an excellent commission structure, and their team is always ready to assist and provide unwavering support. Highly recommended by spicycasinos.com",
           
            description8:"CasinoMentor just started our journey with Nabcasino. Our manager was very straight, reliable and helped to set everything up on the highest level. We're looking forward to grow our partnership.",
        
            description9:"  NAB Affiliates is one of our main partners, and rest assured we can recommend them as a reliable and trusted affiliate program for webmasters. Great communication, great brand to promote, lot of accepted geo's. You'll have great results with NAB",
            
            description10:"NAB Affiliates has been a fantastic partner for us, providing a top-notch casino experience that truly resonates with our audience. Their affiliate team is highly professional, responsive, and always willing to help. We're excited to continue this successful partnership",

            description11:"NAB Affiliates has exceeded our expectations, consistently delivering on their promises and always ready to assist, which we appreciated above all else. However, an additional highlight working with these guys has been the fantastic conversion that we’ve seen from clicks to first deposits. All in all, highly recommended!",

            description12:"Working with NAB Affiliates has been a highly rewarding experience for our business.  Their expertise and support make collaboration seamless and effective. We're thrilled with the results we've achieved together!",
          
            description13:"We are delighted to partner with Nabcasino, a brand that stands out for its excellent service and transparency. Our collaboration has been both rewarding and enjoyable, and we look forward to achieving even greater success together.",
        
            description14:"Slotpicker recently partnered with Nab Affiliates. The affiliate manager is super kind and helpful, and it was really easy to get started promoting NAB Casino. Although Slotpicker hasn't been in the business for too long and is just starting out, we aren't treated that way. Looking forward to growing together.",

            description15: "We have been dealing with NAB Affiliates for some time now, and in our humble opinion they offer one of the best casino affiliate partners out there. They have not only high quality casino brand, but they also offer exclusive deals, extremely good support and fast payments. All in all, we recommend them to everyone who want earn decent money!",
          
            description16: "NAB Affiliates is a good example of how the best casino partners programme should look like in practice. People who work for this company are very kind and helpful, and we are sure that our cooperation will be long and fruitful. Besides, dealing with the them has given our business a huge kick and motivation for further work. Keep it up!",
            
            description17: "For us, NAB Affiliates is one the best affiliate programmes in the i-gaming industry. We have immense pleasure to promote their premium casino at our portal. Moreover, with these guys we can not only earn more and faster, but we can also give our visitors exclusive bonuses and free spins. Taking into account the above, we would honestly recommend this affiliation to everyone who want to earn big money with little effort.",
            
            description18: "Partnership with NAB Affiliates and their online casino is pure pleasure for us. We have been promoting them for some time and have never been disappointed. So, if you want to get decent commission and give your business a boost you should join them - as we did so!",
            
            description19: "If you are looking for an honest affiliate partner with timely payments, NAB Affiliates seems to be very good choice. Besides, with their premium brand we have been able to earn more and faster comparing to others. Last but not least, they always give us what we want, ranging from exclusive marketing materials and deals to professional advice. We have no doubt that they are worth our recommendation to others.",
            
            description20: "NAB Affiliates makes our life easier and happier! With their affiliate program we can provide our users exclusive bonuses, free spins and VIP promotions like no others! In addition, they offer a very decent commission plan with quick payments. In conclusion, big applause for the Just Affiliates team from our “FSMC” family.",
            
            description21: "NAB Affiliates is more than any casino affiliate program! We have been dealing with them for some time and our cooperation is exemplary. With these guys we was able to elevate our business to a higher level and increase gaming experience amongst our players. Thank you!",
            
            description22: "We highly recommend NAB Affiliates to all those who are involved in online gambling business. Our experience with them has been exceptionally good up to now and we are sure it will continue. All in all, we can only say good words about them: Good job!, keep it up!",
            
            description23: "Since we joined NAB Affiliates everything have been working very well from the first day. With fully licensed and regulated online casino, exclusive deals, fast payments, and prime support, we can blindly recommend this website for all affiliates. If we had to rate our partnership with them it would be 5/5 in golden stars!",
            
            description24: "NAB Affiliates is one of the best casino affiliate programmes we have ever dealt with. Here we can find good online casino, fast payments, exclusive bonuses, and more… – all under one umbrella! It’s also worth adding that people behind this company are extremely helpful and professional. Just give it a try and you will not regret your decision. We wish you all big earnings!",
            
            description25: "We are very excited to be working with NAB Affiliates. Their team has been extremely helpful and co-operative during preparation of high quality content. As for their online casinos, they are very strong, with some excellent offers for both new and existing players. We’re very much looking forward to a profitable partnership.",
            
            description26: "As an affiliate the thing that you have on top priority is always trust. Definitely, NAB Affiliates is one of the most secure choices out there. After all, they show professionalism in every way. ",
            
            description27: "NAB Affiliates is our favourite casino affiliate programme! Those who work for this company are very nice and professional. Besides, dealing with the them has given our business boost and motivation to work even harder. Hats off! ",
            
            description28: "NAB Affiliates have a fantastic online casino and team that put a lot effort on retention to make the partnership great success. They are generous towards both players and affiliates. Freispiele.org highly recommend these guys!",
            
            description29: "We really like cooperation with NAB Affiliates - appreciate the friendly approach of these guys and mhonestly recommend them to all those who are involved in the iGaming bizz!",
            
            description30: "We are very happy to become a member of the NAB Affiliates. This is a very good casino affiliate program with very good people behind the scenes! All thumbs UP!",

            description31: "We truly recommend NAB Affiliates to all those who are into online gaming industry. Our experience with is superb up to now and we are sure it will be maintained same level or above. All in all, we can only say to them: Thank you guys! Keep it up!",

            description32: "NAB Affiliates has been a fantastic partner for BonusWanted.com, with a top-tier brand and strong conversion rates. Their responsive team and high professionalism make day-to-day collaboration efficient and rewarding. Strongly recommended by bonuswanted.com",

            description33: "Since I started working with NAB Affiliates, my experience has been exceptional. Their platform is user-friendly and designed to maximize results. The transparency in payments and the ongoing support they provide have given me the confidence that I’m working with a professional company dedicated to the success of their affiliates",

            description34: "Nab Affiliates stands out with a great affiliate support, fast payments, and transparent reporting tools. We are listing Nab Casino on Casino-Groups.com and are very happy with the results of the brand in terms of conversions and retention. Highly recommended!",
             
            description35:"Partnering with NAB Affiliates has been a game-changer for us. Their dedicated team, transparent reporting, and high-converting brand make them a top choice for affiliates looking to maximize earnings",

            description36:"We've worked with numerous affiliate programs, but NAB Affiliates stands out with its reliability and consistent performance. Payments are always on time, and the marketing support is outstanding!",
          
            description37:"NAB Affiliates delivers exactly what affiliates need—a trustworthy brand, fair commission structures, and a team that truly supports its partners.",
       
            description38:"From excellent conversion rates to responsive account managers, NAB Affiliates has exceeded our expectations. Their platform is easy to use, and the results speak for themselves!",
           
            description39:"NAB Affiliates is a reliable partner with competitive deals and strong player retention. If you're looking to grow your revenue with a trusted casino brand, this is the program to join!",
       
            ShowMore:"Show more"
          },


         },
      },
    

    FR: {
      translation: {
        NavBar: {
          BecomeAPartner: "Devenir partenaire",
          News: "Actualités",
          Advantages: "Avantages",
          ContactUs: "Contactez-nous",
          LogIn: "connecter",
          SignUp: "S'inscrire",
        },
        Flag: {
          iconClassName: "flag-icon flag-icon-fr me-1",
        },
        StartTheRace: {
          EarnMoreWithOur: "Gagnez plus avec notre",
          JoinOurGlobal: "Rejoignez notre mondial",
          AffiliateProgram: " Programe affiliation",
          BecomeAPartner: " Devenez partenaire",
          ReferOthersTo: "Référez d'autres à",
          nabcasino: " nabcasino.com ",
          throughYourLinks:
            "via vos liens, gagnez des commissions sur leurs inscriptions et leurs jeux.",
        },

        BecomeAPartner: {
          titleGray: "Devenir",
          titleWhite: "partenaire",
          TexteWhite: "Nabcasino.com ",
          TexteGray:
            "est l'endroit où vous pouvez profiter de cotes compétitives et de la meilleure expérience de paris Bitcoin. Notre équipe s'efforce de vous offrir un parcours de paris enrichissant, amusant et équitable, en vous proposant une plateforme interactive de premier ordre.",
          EarnRevenuetitle: "Gagner des revenus",
          EarnRevenuetext:
            "Gagnez jusqu'à 50% de part de revenus sans quota en tant qu'affilié!",
          GameOnTitle: "C'est parti",
          GameOntext:
            "Explorez une excitation sans fin avec plus de 5000 jeux dans notre casino. Des classiques aux nouveautés, nous couvrons votre aventure de jeu!",
          GlobalReachTitle: "Portée mondiale",
          GlobalReachtext:
            "Découvrez l'accessibilité mondiale de notre plateforme, atteignant des utilisateurs du monde entier avec prise en charge de 16 langues.",
        },

        Casino: {
          Title: "Casino royale",
          smallText:
            'Entrez dans le monde du glamour et du hasard dans notre section de premier <span class="SportsToPlaysmallTextwhite">Casino!</span>',
          LiveCasinoTitle: "Casino en direct",
          LiveCasinoText:
            "Interagissez avec de vrais croupiers dans notre casino en direct. Action en temps réel pour ceux qui recherchent le frisson du casino.",
          CalssicslotsTitle: "Machines à sous classiques",
          CalssicslotsText:
            "Faites tourner les rouleaux et ressentez l'excitation des machines à sous classiques. Divertissement intemporel avec une touche de chance et de nostalgie.",
          TableGamesTitle: "Jeux de table",
          TableGamesText:
            "Défiez les probabilités avec une variété de jeux de table. Du blackjack à la roulette, c'est votre stratégie contre la maison.",
        },

        SportsPlay: {
          Title: "Jeux de sport",
          smallText:
            'Plongez au cœur de l\'action avec notre section <span class="SportsToPlaysmallTextwhite">Sports</span> à portée de main!',
          HorseRacingTitle: "Courses de chevaux",
          HorseRacingText:
            "Ressentez l'excitation des courses de chevaux - une action rapide et des moments palpitants vous attendent!",
          AussieRulesTitle: "Règles australiennes",
          AussieRulesText:
            "Profitez des règles australiennes - des jeux aériens et une compétition intense pour un plaisir sans fin!",
          FootballTitle: "Football",
          FootballText:
            "Plongez dans l'action du football - buts, stratégies et excitement pour tous les fans!",
        },

        Advantages: {
          Title: "Avantages",
          smallText:
            'Faites équipe avec nous et obtenez jusqu\'à <span class="SportsToPlaysmallTextwhite">50% de part de revenus</span> - aussi simple que cela',
          DetailedStatsTitle: "Statistiques détaillées",
          DetailedStatsText:
            "Suivez les campagnes, les clients et le trafic en temps réel avec notre système d'affiliation efficace.",
          LifetimeRevenueTitle: "Revenu à vie",
          LifetimeRevenueText:
            "Débloquez des gains illimités avec une augmentation du trafic. Notre rémunération généreuse garantit que vous gagnez sur chaque joueur que vous attirez.",
          FastAffiliatePaymentsTitle: "Paiements d'affiliation rapides",
          FastAffiliatePaymentsText:
            "Comptez sur des paiements rapides - commissions traitées et payées dans les cinq premiers jours ouvrables de chaque mois pour nos affiliés.",
        },
        Testimonials: {
          Title: "Témoignages",
          Subtitle1: "Voyez comment nos affiliés",
          SubtitleWhite: " réussissent ",
          Subtitle2: "avec nous !",
        
          description1: "Collaborer avec les affiliés de NAB a été excellent. Leurs offres de cryptomonnaie enrichissent notre site, et l'équipe est toujours utile. L'ajout de nabcasino.com a été facile grâce à leur innovation et leur solide leadership. Nous attendons avec impatience de continuer à réussir avec un programme d'affiliation aussi fiable.",

          description2:"From the beginning, working with Nab Affiliates has been a pleasure. Our account manager has established a great rapport with us and has been very professional and responsive to our needs. We wholeheartedly advise working with  Nab Affiliates!",
 
          description3:"Pour The Gambler Bay, s'associer à NAB AFFILIATES est une étape très importante. Nous avons longtemps œuvré pour cela et nous croyons que c'est un investissement mutuellement bénéfique de nos efforts. NAB AFFILIATES incarne des fonctionnalités absolument indispensables pour les programmes d'affiliation modernes. Jouer pour de l'argent réel dans les casinos en ligne est une étape importante, et vous soutenez pleinement les clients de vos casinos!",

          description4:"Nab Affiliates continue de définir les normes dans leur secteur. Leur dévouement à obtenir des résultats de premier ordre et leur approche proactive en font un atout irremplaçable. Nous recommandons vivement leurs services.",
            
          description5:"Le marketing d'affiliation est devenu une stratégie de communication puissante pour notre organisation, créant de nouvelles opportunités de croissance et augmentant les bénéfices. En collaborant avec un affilié fiable, NABCASINO, nous avons considérablement élargi notre portée et renforcé la fidélité des clients en recommandant des services qui répondent véritablement à leurs besoins.",
        
          description6:"Travailler avec des professionnels est agréable. Ils possèdent un casino réputé avec des partenariats d'affiliation intéressants dans de nombreux pays. Je recommande vivement Nab Affiliates.",     
    
          description7:"Travailler avec NAB AFFILIATES a été une expérience fantastique. Leur programme d'affiliation offre une excellente structure de commission, et leur équipe est toujours prête à aider et à offrir un soutien indéfectible. Fortement recommandé par spicycasinos.com.",
       
          description8:"CasinoMentor vient juste de commencer notre aventure avec Nabcasino. Notre gestionnaire a été très direct, fiable et nous a aidés à tout mettre en place au plus haut niveau. Nous sommes impatients de développer notre partenariat",
       
          description9:"NAB Affiliates est l'un de nos principaux partenaires, et soyez assurés que nous pouvons les recommander comme un programme d'affiliation fiable et de confiance pour les webmasters. Excellente communication, excellente marque à promouvoir, avec de nombreux territoires acceptés. Vous obtiendrez d'excellents résultats avec NAB.",
       

          description10:"NAB Affiliates a été un partenaire fantastique pour nous, offrant une expérience de casino de premier ordre qui résonne véritablement auprès de notre audience. Leur équipe d'affiliation est extrêmement professionnelle, réactive et toujours prête à aider. Nous sommes ravis de poursuivre ce partenariat fructueux.",

          description11:"NAB Affiliates a dépassé nos attentes, respectant constamment leurs promesses et étant toujours prêts à nous assister, ce que nous avons particulièrement apprécié. Cependant, un autre point fort de notre collaboration avec eux a été le formidable taux de conversion que nous avons observé, allant des clics aux premiers dépôts. En résumé, fortement recommandé !",

          description12:"Travailler avec NAB Affiliates a été une expérience extrêmement enrichissante pour notre entreprise. Leur expertise et leur soutien rendent la collaboration fluide et efficace. Nous sommes ravis des résultats que nous avons obtenus ensemble !",
         
          description13:"Nous sommes ravis de nous associer à Nabcasino, une marque qui se distingue par son excellent service et sa transparence. Notre collaboration a été à la fois enrichissante et agréable, et nous nous réjouissons à l'idée de réaliser ensemble des succès encore plus grands.",
      
          description14:" Slotpicker a récemment établi un partenariat avec Nab Affiliates. Le responsable des affiliés est très aimable et serviable, et il a été vraiment facile de commencer à promouvoir NAB Casino. Bien que Slotpicker ne soit pas dans le secteur depuis très longtemps et qu'il débute tout juste, nous ne sommes pas traités de cette façon. Nous sommes impatients de grandir ensemble.",
         
          
          description15: "Nous collaborons avec NAB Affiliates depuis un certain temps et, selon nous, ils offrent l’un des meilleurs programmes d’affiliation pour les casinos. Ils proposent non seulement une marque de casino de haute qualité, mais également des offres exclusives, un support exceptionnel et des paiements rapides. En résumé, nous les recommandons à tous ceux qui souhaitent gagner de l’argent de manière décente !",
          
          description16: "NAB Affiliates est un excellent exemple de ce à quoi un programme de partenaires de casino devrait ressembler en pratique. Les personnes qui travaillent pour cette entreprise sont très aimables et serviables, et nous sommes convaincus que notre coopération sera longue et fructueuse. De plus, collaborer avec eux a donné un énorme coup de pouce à notre entreprise et une motivation pour continuer à progresser. Continuez comme ça !",
          
          description17: "Pour nous, NAB Affiliates est l’un des meilleurs programmes d’affiliation de l’industrie du i-gaming. Nous avons un immense plaisir à promouvoir leur casino premium sur notre portail. De plus, avec eux, nous pouvons non seulement gagner plus rapidement, mais également offrir à nos visiteurs des bonus exclusifs et des tours gratuits. En prenant tout cela en compte, nous recommanderions honnêtement cette affiliation à tous ceux qui souhaitent gagner gros avec peu d’effort.",
          
          description18: "Le partenariat avec NAB Affiliates et leur casino en ligne est un pur plaisir pour nous. Nous les promouvons depuis un certain temps et nous n’avons jamais été déçus. Donc, si vous voulez obtenir une commission décente et booster votre activité, vous devriez les rejoindre - comme nous l’avons fait !",
          
          description19: "Si vous cherchez un partenaire d’affiliation honnête avec des paiements ponctuels, NAB Affiliates semble être un très bon choix. De plus, grâce à leur marque premium, nous avons pu gagner plus et plus rapidement par rapport à d’autres. Enfin, ils nous offrent toujours ce que nous voulons, allant des matériaux marketing exclusifs aux conseils professionnels. Nous n’avons aucun doute qu’ils méritent d’être recommandés à d’autres.",
          
          description20: "NAB Affiliates rend notre vie plus facile et plus joyeuse ! Grâce à leur programme d’affiliation, nous pouvons offrir à nos utilisateurs des bonus exclusifs, des tours gratuits et des promotions VIP comme aucun autre ! En outre, ils proposent un plan de commission très intéressant avec des paiements rapides. En conclusion, une grande ovation pour l’équipe NAB Affiliates de la part de notre famille « FSMC ».",
          
          description21: "NAB Affiliates est bien plus qu’un simple programme d’affiliation de casino ! Nous collaborons avec eux depuis un moment, et notre coopération est exemplaire. Grâce à eux, nous avons pu faire passer notre activité à un niveau supérieur et améliorer l’expérience de jeu de nos joueurs. Merci !",
          
          description22: "Nous recommandons vivement NAB Affiliates à tous ceux qui sont impliqués dans le secteur des jeux d’argent en ligne. Notre expérience avec eux a été exceptionnellement bonne jusqu’à présent, et nous sommes certains qu’elle continuera de l’être. En résumé, nous ne pouvons que dire du bien d’eux : bon travail, continuez ainsi !",
          
          description23: "Depuis que nous avons rejoint NAB Affiliates, tout fonctionne parfaitement dès le premier jour. Avec un casino en ligne pleinement licencié et régulé, des offres exclusives, des paiements rapides et un support de premier ordre, nous pouvons recommander ce site à tous les affiliés les yeux fermés. Si nous devions évaluer notre partenariat avec eux, ce serait 5/5 étoiles dorées !",
          
          description24: "NAB Affiliates est l’un des meilleurs programmes d’affiliation de casino avec lesquels nous avons travaillé. On y trouve un bon casino en ligne, des paiements rapides, des bonus exclusifs et bien plus encore... tout cela sous un même toit ! Il convient également de noter que les personnes derrière cette entreprise sont extrêmement serviables et professionnelles. Essayez-les, et vous ne regretterez pas votre décision. Nous vous souhaitons à tous de gros gains !",
          
          description25: "Nous sommes très enthousiastes à l’idée de travailler avec NAB Affiliates. Leur équipe a été extrêmement utile et coopérative lors de la préparation de contenus de haute qualité. Quant à leurs casinos en ligne, ils sont très solides, avec d’excellentes offres pour les nouveaux et anciens joueurs. Nous attendons avec impatience un partenariat rentable.",
          
          description26: "En tant qu’affilié, la priorité absolue est toujours la confiance. Définitivement, NAB Affiliates est l’un des choix les plus sûrs sur le marché. Après tout, ils font preuve de professionnalisme à tous points de vue.",
          
          description27: "NAB Affiliates est notre programme d’affiliation de casino préféré ! Les personnes qui travaillent pour cette entreprise sont très sympathiques et professionnelles. De plus, collaborer avec eux a donné un coup de pouce à notre activité et nous a motivés à travailler encore plus dur. Chapeau bas !",
          
          description28: "NAB Affiliates dispose d’un fantastique casino en ligne et d’une équipe qui met beaucoup d’efforts sur la fidélisation pour faire de ce partenariat un grand succès. Ils sont généreux envers les joueurs et les affiliés. Freispiele.org recommande vivement ces personnes !",
          
          description29: "Nous apprécions vraiment la coopération avec NAB Affiliates - leur approche amicale est très appréciée, et nous les recommandons honnêtement à tous ceux qui sont impliqués dans le secteur de l’iGaming !",
          
          description30: "Nous sommes très heureux de devenir membre de NAB Affiliates. C’est un excellent programme d’affiliation de casino avec de très bonnes personnes derrière les coulisses ! Tous les pouces en l’air !",
          
          description31: "Nous recommandons sincèrement NAB Affiliates à tous ceux qui sont dans l’industrie des jeux en ligne. Notre expérience avec eux est superbe jusqu’à présent, et nous sommes sûrs qu’elle restera au même niveau ou au-delà. En résumé, nous ne pouvons que leur dire : merci les gars ! Continuez comme ça !",
         
          description32: "NAB Affiliates a été un partenaire fantastique pour BonusWanted.com, avec une marque de premier ordre et des taux de conversion élevés. Leur équipe réactive et leur grand professionnalisme rendent la collaboration quotidienne efficace et gratifiante. Fortement recommandé par bonuswanted.com.",

          description33: "Depuis que j'ai commencé à travailler avec NAB Affiliates, mon expérience a été exceptionnelle. Leur plateforme est conviviale et conçue pour maximiser les résultats. La transparence des paiements et le soutien constant qu'ils offrent m'ont donné la confiance de travailler avec une entreprise professionnelle dédiée au succès de ses affiliés.",
         
          description34: "Nab Affiliates se distingue par un excellent support pour les affiliés, des paiements rapides et des outils de reporting transparents. Nous listons Nab Casino sur Casino-Groups.com et sommes très satisfaits des résultats de cette marque en termes de conversions et de fidélisation. Hautement recommandé !",
         
          description35:"S'associer avec NAB Affiliates a été un véritable changement pour nous. Leur équipe dévouée, leurs rapports transparents et leur marque à forte conversion en font un choix de premier plan pour les affiliés cherchant à maximiser leurs revenus",

          description36: "Nous avons travaillé avec de nombreux programmes d'affiliation, mais NAB Affiliates se distingue par sa fiabilité et ses performances constantes. Les paiements sont toujours ponctuels et le support marketing est exceptionnel !",
  
          description37: "NAB Affiliates offre exactement ce dont les affiliés ont besoin : une marque de confiance, des structures de commission équitables et une équipe qui soutient réellement ses partenaires.",
          
          description38: "Avec d'excellents taux de conversion et des gestionnaires de compte réactifs, NAB Affiliates a dépassé nos attentes. Leur plateforme est facile à utiliser, et les résultats parlent d'eux-mêmes !",
          
          description39: "NAB Affiliates est un partenaire fiable avec des offres compétitives et une forte rétention des joueurs. Si vous cherchez à augmenter vos revenus avec une marque de casino de confiance, c'est le programme à rejoindre !",
        
          ShowMore:"Voir plus"

        }, 

       
       
         Events: {
          Title: "Événements",
          SubtitleWhite: "Rejoignez-nous",
          Subtitle: "pour les événements à venir et restez connectés !",
        },
        
        
        ContactUs: {
          Title:
            'Contactez <span class="SportsToPlaysmallTextwhite popins-bold"> notre équipe d\'affiliation</span> ',
          smallText:
            'N\'hésitez pas à <span class="ContactUsFeelFreewhite popins-bold"> nous contacter </span>  à tout moment. Nous vous répondrons dès que possible.',
          SendUsMessage: "Envoyez-nous un message",
          iHaveReadAndAgreed:
            " J'ai lu et accepté les <span class=\"ContactUsiHaveReadAndAgreedUnderline popins-bold\"> conditions d'utilisation et la politique de confidentialité </span>",
          SendRequest: "Envoyer demande",
          YourEmail: "VOTRE E-MAIL*",
          MessageText: "TEXTE DU MESSAGE*",
        },
        Footer: {
          Textup: "Devenir partenaire",
          TextMidle: "Contactez-nous",
          TextDown: "Termes et Conditions",
          AllRights: "Tous droits réservés, Nouveau Age N.V",
          TextDownTerms:"Termes et Conditions"
        },
      },
      },
    },
  });

export default i18n;
